@media (max-width: 1146px) {
  .dash-btm-cards-cont {
    width: 100% !important;
  }

  .m-r-14px {
    margin-right: 0 !important;
  }
}

@media (min-width: 700px) and (max-width: 1130px) {
  .mid-sc-res {
    width: 90% !important;
  }
}

@media (max-width: 1110px) {
  .asn-add-inner-cont-res {
    width: 100% !important;
  }
}

.user-action-cont {
  display: flex;
  justify-content: space-between;
}

.user-img {
  border-radius: 50%;
}
.pnote-cont {
  font-size: 0.7rem;
  background-color: #59626e;
  padding: 0.1rem 0.3rem;
  border-radius: 0.1rem;
  color: #ffff;
}

.status-inner-cont {
  display: flex;
  gap: 0.3rem;
}

.status-span {
  font-size: 0.7rem;
  background-color: #71c015;
  padding: 0.1rem 0.3rem;
  border-radius: 0.1rem;
  color: #ffff;
}

.status-span2 {
  font-size: 0.7rem;
  background-color: orange;
  padding: 0.1rem 0.3rem;
  border-radius: 0.1rem;
  color: #ffff;
}

.user-img {
  width: 3.3rem;
  height: 3.3rem;
}

p {
  margin: 0;
}

.status-heading-container {
  display: flex;
  justify-content: space-between;
}

.status-icon-span {
  display: flex;
  gap: 0.3rem;
  color: #3790b5;
  align-items: center;
}

.dropdown-container .dropdown-item {
  color: #ffff !important;
}

.dropdown-container .dropdown-toggle::after {
  display: none;
}

.user-list-card-cont:hover {
  border-bottom: 3px solid #59626e;
}

.pointer {
  cursor: pointer;
}

.acc-payment-cont {
  width: 42%;
  margin: 3.2rem auto 2rem 5rem;
}

.blue-fnt {
  color: #247395;
}

.custom-file-tech-bank::-webkit-file-upload-button {
  visibility: hidden;
}
.custom-file-tech-bank {
  border: 1px dashed #c5c5c5;
  padding: 6px;
  width: 100%;
  margin: 5px 0;
  height: 43px;
}
.custom-file-tech-bank::before {
  content: "Upload Confirmation Letter";
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f4f4f4;
  border-radius: 3px;
  padding: 7px 8px;
  outline: none;
  white-space: nowrap;
  font-size: 14px;
  font-family: "f2";
  width: 100%;
  height: 30px;
  text-align: center;
}

.custom-file-tech-bank-new::-webkit-file-upload-button {
  visibility: hidden;
}
.custom-file-tech-bank-new {
  border: 1px dashed #c5c5c5;
  padding: 6px;
  width: 100%;
  margin: 5px 0;
  height: 43px;
}

.custom-file-tech-bank-new::before {
  content: "Drag and drop your files here or Upload a file";
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f4f4f4;
  border-radius: 3px;
  padding: 7px 8px;
  outline: none;
  white-space: nowrap;
  font-size: 14px;
  font-family: "f2";
  width: 100%;
  height: 30px;
  text-align: center;
}

.supplier-upload-file-upload-text {
  position: absolute;
  top: 27%;
  left: 38%;
}

.h-2r {
  height: 2.1rem;
}

.wid-9r {
  width: 9rem;
}

.fnt-fam-f2 {
  font-family: "f2" !important;
}

.wid-5r {
  width: 5rem;
}

.wid-11r {
  width: 11rem;
}

.blue-trans-button {
  color: #247395;
  border: 2px solid #247395;
}

.wid-9_5r {
  width: 9.5rem;
}

.h-2_5r {
  height: 2.5rem;
}

.fs-1r {
  font-size: 1.1rem !important;
}
.po-scope-table-delivery-card {
}

.fs-0-5r {
  font-size: 0.5rem;
}
.arw-svg {
  position: absolute;
  font-size: 30px;
  top: -20px;
  right: 8px;
  color: #247395;
}
.po-scope-table-delivery-card h4 {
  font-size: 16px;
  margin-bottom: 0;
  color: #000;
}
.po-scope-table-delivery-card {
  width: 350px;
  background: white;
  box-shadow: 0px 0px 8px -1px #c6c6c6;
  border-top: 3px solid #247395;
  position: absolute;
  z-index: 1;
  right: 70px;
  padding: 10px;
  .po-sc-drop-down {
    font-size: 35px;
    font-family: "f3";
  }
  .po-delivery-date-model-tick-cont-active {
    display: flex;
    color: white;
    align-items: center;
    justify-content: center;
    position: relative;
    .line1 {
      width: 2px;
      height: 40px;
      position: absolute;
      top: 30px;
      // left: 13px;
    }
    svg {
      border-radius: 100px;
      font-size: 20px;
      height: 26px;
      width: 26px;
    }
  }
  h6,
  p {
    font-size: 13px;
    span {
      color: #a09f9f;
    }
  }
}

.po-delivery-date-model-tick-cont-non-active {
  opacity: 50%;
  background: #c3c7c1;
  height: 30px;
  width: 30px;
  line-height: 23px;
  border-radius: 100px;
  font-size: 1.3rem;
  color: white;
  text-align: center;
}

.po-card-drop-down {
  position: absolute;
  border-radius: 0.2rem;
  width: 14rem;
  font-family: "f1";
  right: 0.2rem;
  top: 40px;
  background: #3c3c3c;
  color: #fff;
  font-size: 13px;
}

.po-card-drop-down::before {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  top: -7px;
  right: 5px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #3c3c3c;
}
.po-card-drop-down1 {
  position: absolute;
  border-radius: 0.2rem;
  font-family: "f1";
  right: 25px;
  top: 40px;
  background: #3c3c3c;
  color: #fff;
  font-size: 13px;
}
.po-card-drop-down1::before {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  top: -7px;
  right: 5px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #3c3c3c;
}

.text-wht {
  color: #ffff !important;
}

.txt-org {
  color: #e2b473 !important;
}

.po-acknoweld-card-cont {
  // position: absolute;
  // box-shadow: 0px 0px 8px -1px #c6c6c6;
  // border: 1px solid #dbdbdb;
  // width: 29.9rem;
  // height: 21.3rem;
  // top: 33%;
  // left: 30%;
  // background: white;
  // z-index: 1;
}

.po-card-radio-btn {
  font-size: 0.9rem;
  font-family: "f1";
}

.po-filter-card-dropdown {
  border: none;
  border-bottom: 1px solid #dbdbdb;
}

.po-filter-card-cont {
  // position: absolute;
  // box-shadow: 0px 0px 8px -1px #c6c6c6;
  // border: 1px solid #dbdbdb;
  // width: 41rem;
  // height: 23rem;
  // top: 33%;
  // left: 30%;
}

.po-card-btn {
  padding: 1px 5px;
  border-radius: 0.1rem;
}

.fs-1r {
  font-size: 1rem;
}

.clear-can-btn {
  color: black;
  height: 2rem;
  width: 4rem;
  background-color: #e6e6e6;
}

.sub-app-btn {
  width: 5rem;
  height: 2rem;
}

.po-acknoweld-card-cont {
  z-index: 1111;
  .modal-content {
    border: none !important;
    padding: 0.7rem 1.5rem;
  }
}

.po-filter-card-cont {
  z-index: 1111;
  .modal-content {
    border: none !important;
  }
}

.border-top-blue-thick {
  .modal-content {
    border-top: 3px solid #247395 !important;
    border-radius: 0 !important;
  }
}

.input-box-border {
  input[type="date"]::-webkit-datetime-edit-text {
    -webkit-appearance: none;
    display: none;
  }
  input[type="date"]::-webkit-datetime-edit-month-field {
    -webkit-appearance: none;
    display: none;
  }
  input[type="date"]::-webkit-datetime-edit-day-field {
    -webkit-appearance: none;
    display: none;
  }
  input[type="date"]::-webkit-datetime-edit-year-field {
    -webkit-appearance: none;
    display: none;
  }
}

.dc-date-cont-text {
  position: absolute;
  top: 6px;
  left: 20px;
}

.wid-150perc {
  width: 150%;
}

.bg-light-blue {
  background-color: #e6fbff;
}

.dc-rs-btm-drop-cont {
  width: 100%;
  background: white;
  font-size: 14px;
  box-shadow: 0px 0px 8px -1px #c6c6c6;
  font-family: "f2";
  border: 1px solid #dbdbdb;
  height: 41px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.fs-13px {
  font-size: 13px;
}

.trans-x-10px {
  transform: translateX(10px);
}

.wid-212px {
  width: 212px;
}

.wid-97-perc {
  width: 97%;
}

.update-sp-modal {
  .modal-content {
    width: 410px;
  }
}

.light-vilot-bg {
  background-color: #f4f3f9;
}

.dashbord-card-cont {
  background-color: #ffff;
  box-shadow: 0px 0px 8px -1px #c6c6c6;
}

.dash-th-hgt {
  height: 3rem;
  line-height: 3rem;
}

.dash-tr-hgt {
  height: 2.3rem;
  line-height: 2.3rem;
}

.fs-15px {
  font-size: 15px;
}

.aqua-text {
  color: #05ffba;
}

.wid-220px {
  min-width: 185px;
}

// .bor-thick-right {
//   border-right: 2px solid #dbdbdb;
// }

.wid-141px {
  min-width: 110px;
  max-width: 141px;
}

.dash-btm-cards-cont {
  width: 49%;
}

.wid-122px {
  width: 122px;
}
.m-r-14px {
  margin-right: 20px;
}

.min-w-1kpx {
  min-width: 1010px;
}

.asn-add-show--inner-cont {
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid #dbdbdb;
  border-bottom: 1px solid #dbdbdb;
  height: 33px;
  width: 80px;
}

.asn-add-inner-cont-res {
  width: 70%;
  margin-left: auto;
}

.wid-110px {
  width: 110px;
}

.dc-ship-po-drop-box {
  background-color: #ffff;
  border: 1px solid #dbdbdb;
  border-top: none !important;

  li {
    padding: 3px;
    display: flex;
    align-items: center;
    column-gap: 11px;
    font-family: "f1";
    font-size: 13px;
    input {
      cursor: pointer;
      width: 10%;
      height: 10%;
    }
    span {
      width: 90%;
    }
  }
}

.asn-drop-down {
  background-color: #ffff;
  padding-left: 5px;
  width: 79px;
  right: 2px;
  left: 0.5px;
  top: 30px;
}

.pad-s-7px {
  padding-left: 7px;
}

.dash-card-choice-cont {
  width: 110px;
  margin-right: 23px;
}

.wid-92 {
  width: 92%;
  margin: auto;
}

.h-4r {
  height: 4.1rem;
}

.invoice-bank-cont {
  width: 123px;
  display: inline-block;
}

.custom-file-dc-input:hover::before {
  border-color: black;
}
.custom-file-dc-input:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

.custom-file-dc-input::-webkit-file-upload-button {
  visibility: hidden;
}
.custom-file-dc-input {
  border: 1px dashed #c5c5c5;
  padding: 6px;
  width: 100%;
}
.custom-file-dc-input::before {
  content: "Drag and drop Your files here or Upload a file";
  display: inline-block;
  background: #f4f4f4;
  border-radius: 3px;
  padding: 7px 8px;
  outline: none;
  white-space: nowrap;
  font-size: 14px;
  font-family: "f2";
  width: 100%;
  text-align: center;
}
.custom-file-dc-input:hover::before {
  border-color: black;
}
.custom-file-dc-input:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

.dash-choice-active {
  color: #247395;
  text-align: center;
  border-bottom: 2px solid #247395;
}

.po-sp-span-wid {
  width: 95px;
  display: inline-block;
}

.po-sp-left-ship-details-span-wid {
  width: 143px;
  display: inline-block;
}

.fs-12px {
  font-size: 12px;
}

.adproduct-rgt-div-img {
  width: 75px;
  border-radius: 2px;
  height: 75px;
}

.bg-light-grey {
  background-color: #d5d5d5;
}

.adproduct-rgt-div-add-img {
  width: 75px;
  border-radius: 2px;
  height: 75px;
  box-shadow: 0px 0px 8px -1px #c6c6c6;
  border: 1px solid #dbdbdb;
}

.input-box {
  .ph_input {
    .react-tel-input {
      width: 168px;
    }
    svg {
      height: 13px;
      margin-top: 5px;
    }
  }
}
