@import "../../assets/sass/fonts.scss";
@import "../../assets/sass/theme.scss";

@media (max-width: 750px) {
  .ad-file-upload-text {
    position: absolute;
    font-size: 12px !important;
    top: 47px;
    left: 15% !important;
    z-index: 1;
  }
}

@media (max-width: 450px) {
  .ad-file-upload {
    width: 120% !important;
  }
  .admin-create-bc-cont {
    width: 100% !important;
    margin: 0 25px !important;
  }
}

.t3 {
  color: $t3;
}

.t6 {
  color: $t6;
}

.no-apprence {
  appearance: none;
}

.ad-file-upload-text {
  position: absolute;
  font-size: 13px;
  top: 47px;
  left: 30%;
  z-index: 1;
}

.ad-file-upload {
  .lkjDVC .sc-dkrFOg {
    padding: 20px;
  }
}

.pad-20px {
  padding: 20px;
}

.ad-bc-list-btn {
  border-radius: 2px;
  width: 95px;
  height: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bg-light-acqu {
  background-color: #e6fff8;
}

.bg-light-green {
  background-color: #59d559;
}

.ad-bc-table-btn {
  width: 70px;
  height: 23px;
  font-family: "f2";
  align-items: center;
  justify-content: center;
  display: flex;
}

.light-green-bg {
  background-color: #9ad258;
}

.ad-bc-tb-dropdown {
  position: relative;
  border-radius: 0.2rem;
  width: 87px;
  height: 83px;
  font-family: "f2";
  right: 28px;
  background: #3c3c3c;
  color: #fff;
  font-size: 13px;
}

.ad-bc-tb-dropdown::before {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  top: -7px;
  left: 26px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #3c3c3c;
}

.ad-bc-tb-drop-down-inner-cont {
  height: 15px;
}

.ad-dash-th-hgt {
  height: 2rem;
  line-height: 2rem;
}

.ad-table-search-box {
  height: 24px;
  border: 1px solid #c5c5c5;
  input {
    color: #000;
    padding: 0 10px;
    display: flex;
    height: 100%;
    width: 100%;
    border: 0;
    &::placeholder {
      color: #c6c6c6;
    }
  }
}

.ad-create-bc-drop-cont {
  input[type="checkbox"] {
    accent-color: #4aa1ac;
  }
}

.ad-create-broadcast {
  input[type="date"]::-webkit-datetime-edit-text {
    -webkit-appearance: none;
    display: none;
  }
  input[type="date"]::-webkit-datetime-edit-month-field {
    -webkit-appearance: none;
    display: none;
  }
  input[type="date"]::-webkit-datetime-edit-day-field {
    -webkit-appearance: none;
    display: none;
  }
  input[type="date"]::-webkit-datetime-edit-year-field {
    -webkit-appearance: none;
    display: none;
  }
}

.admin-create-bc-cont {
  width: 70%;
  margin: auto;
}
