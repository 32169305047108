* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@media only screen and (min-width: 850px) {
  // .footer-cont-res {
  //   position: fixed !important;
  //   bottom: 0 !important;
  //   width: 100% !important;
  // }
}

@media only screen and (max-width: 1200px) and (min-width: 570px) {
  .responsive-width {
    width: 90%;
  }
}

@media only screen and (min-width: 1460px) {
  .xtra-lg-screen-responsive-cont {
    width: 90%;
    margin-right: auto;
  }
}

@media only screen and (min-width: 1500px) {
  .xtra-lg-screen-responsive-cont {
    width: 80%;
    margin-right: auto;
  }
  .invoice-list-cont {
    width: 80%;
    margin: auto;
  }
  .grn-whole-cont {
    width: 80%;
    margin-right: auto;
    margin-left: 3rem !important;
  }
}

@media only screen and (min-width: 1600px) {
  .xtra-lg-screen-responsive-cont {
    width: 70%;
    margin-right: auto;
  }

  .invoice-list-cont {
    width: 60%;
    margin: auto;
  }

  .grn-whole-cont {
    width: 70%;
    margin-right: auto;
    margin-left: 5rem !important;
  }

  // .enquiry-list-cont {
  //   width: 50% !important;
  //   margin: auto !important;
  // }

  .quo-scope-tab-zoom-page {
    width: 70%;
    margin-right: auto;
    margin-left: 8.3rem !important;
  }
  .enqiry-view-2nd-sec {
    margin-left: 2rem;
  }
}

@media only screen and (min-width: 1000px) {
  .quo-scope-btm-tb-2nd-cont {
    width: 82.2%;
  }
}

@media only screen and (min-width: 1700px) {
  .xtra-lg-screen-responsive-cont {
    width: 60%;
    margin-right: auto;
  }
}
@media only screen and (max-width: 770px) {
  .flex-resposive {
    display: block !important;
  }
  .xtra-lg-screen-responsive-cont {
    flex-wrap: wrap;
  }
}
@media only screen and (max-width: 650px) {
  .user-list-userlist-container {
    width: 100% !important;
    margin: 0.2rem !important;
  }
  .user-login-details-cont {
    width: 100% !important;
    margin: 0.2 !important;
  }

  .department-status-container {
    width: 100% !important;
  }
}

@media only screen and (max-width: 470px) {
  .user-login-details-cont {
    overflow-x: scroll !important;
  }
  .pagination-container {
    display: block !important;
  }
  .page-inner-cont {
    width: 20px !important;
    min-width: 100px !important;
    background-color: #247395;

    .page-item.active .page-link {
      z-index: 3;
      color: #fff !important;
      background-color: #247396;
      border-color: #247396;
    }
  }
  .grn-search-bar {
    width: 100%;
    margin-right: 25px;
    padding-right: 20px;
  }
}
.card-container {
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 8px -1px #c6c6c6;
  border: 1px solid #dbdbdb;
  padding: 20px;
  position: relative;
  .sort-drop {
    position: absolute;
    right: 13px;
    top: 64px;
    background: #3c3c3c;
    padding: 3px 5px;
    color: #fff;
    width: 100px;
    font-size: 13px;
    &::before {
      content: "";
      width: 0;
      height: 0;
      position: absolute;
      top: -7px;
      right: 5px;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid #3c3c3c;
    }
    a {
      font-size: 13px;
      padding: 3px 7px;
      width: 100%;
      color: #fff !important;
      display: flex;
    }
  }
  .btn-use-uses {
    background-color: transparent;
    color: white;
    border: none;
    width: 79px;
    text-align: left;
  }
}

.enquiry-list-card-cont {
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 8px -1px #c6c6c6;
  border: 1px solid #dbdbdb;
  padding: 20px;
  position: relative;
  .enq-sort-drop {
    position: absolute;
    left: -33px;
    top: 40px;
    background: #3c3c3c;
    padding: 2px 14px 2px 0px;
    color: #fff;
    font-size: 13px;
    &::before {
      content: "";
      width: 0;
      height: 0;
      position: absolute;
      top: -7px;
      right: 5px;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid #3c3c3c;
    }
    a {
      font-size: 13px;
      padding: 3px 7px;
      width: 100%;
      color: #fff !important;
      display: flex;
    }
  }
}

.user-list-userlist-container {
  width: 80%;
  flex-direction: column;
  margin: auto;
  display: flex;
}

.wid_full {
  width: 100%;
}
.search-bar-cont {
  border: 1px solid #ced4da !important;
  border-radius: 0.2rem;
  padding: 0 10px !important;
}

.search-field {
  border: none !important;
}

.action-page-cont {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  p {
    font-size: 13px;
  }
  select {
    border: 0;
    font-family: "f1";
    font-size: 13px;
  }
  .btn-group {
    button {
      font-family: "f2";
      font-size: 14px;
    }
  }
  .manage-drop-down {
    .dropdown-menu {
      background: #000;
    }
  }
}
.page-inner-cont {
  display: flex;
  justify-content: space-between;
  .page-item.active .page-link {
    z-index: 3;
    color: #fff !important;
    background-color: #247396;
    border-color: #247396;
  }
}

.page-nate-new {
  display: flex;
  justify-content: end;
  .page-item.active .page-link {
    z-index: 3;
    color: #fff !important;
    background-color: #247396;
    border-color: #247396;
  }
}

.page-status-cont {
  display: flex;
  column-gap: 0.3rem;
  align-items: center;
}

.pagination-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 1.5rem 0;
}

.adduser-btn {
  background-color: #247395;
  color: #ffff !important;
  border: none !important;
  border-radius: 0.2rem;
}

.department-list-heading-container {
  background-color: #247395;
  color: #ffff;
}

.department-list-container {
  border: 1px solid #ced4da !important;
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
  border-radius: 0.3rem;
  th {
    font-size: 14px;
    color: #fff;
    font-family: "f2";
    font-weight: 100;
    padding: 10px 20px;
    text-align: center;
    &:first-child {
      text-align: left;
    }
  }
  td {
    font-size: 14px;
    color: #000;
    font-family: "f2";
    font-weight: 100;
    padding: 6px 20px;
    text-align: center;
    &:first-child {
      text-align: left;
    }
  }
  tbody {
    tr {
      border-bottom: 1px solid #eee;
      &:hover {
        background-color: #f6f6f6;
      }
    }
  }
}

.department-dropdown-cont .dropdown-toggle::after {
  display: none;
}

.department-dropdown-cont .dropdown-item {
  color: #ffff !important;
}

.list-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.department-status-container-active {
  color: white;
  background-color: #71c015;
  width: 70px;
  margin: auto;
  border-radius: 2px;
}

.department-status-container-de-active {
  color: white;
  background-color: #cc3131;
  width: 70px;
  margin: auto;
  border-radius: 2px;
}

.add-depat-btn {
  padding: 0.5rem;
}

.user-login-details-cont {
  width: 80%;
  flex-direction: column;
  margin: 1.5rem auto;
  display: flex;
}

.user-login-details-backarrow-cont {
  margin: 1rem 0;
  display: flex;
  column-gap: 0.5rem;
  color: #247395;
  align-items: center;
}
.user-login-detail-img {
  float: left;
  margin: 0 0.5rem 0 0;
}

.notification-cont {
  width: 90%;
  border: 1px solid #dbdbdb;
  margin: auto;
}

.notifi-detail-header-cont {
  border-bottom: 1px solid #dbdbdb;
}

.notifi-detail-cont {
  border-left: 1px solid #dbdbdb;
}
.broadcast-multi-sel-dropdown {
  border-left: 1px solid #dbdbdb;
  border-right: 1px solid #dbdbdb;
  border-bottom: 1px solid #dbdbdb;
}
.font-blue {
  font-size: 11px;
  color: #247395;
}
.font-col-sec {
  color: #247395 !important;
}
.fs-time {
  font-size: 11px;
}

.pdf-cont {
  border: 1px solid #dbdbdb;
  border-radius: 0.3rem;
  width: 250px;
}

.pdf-icon-cont {
  color: #eb5353;
  font-size: 2rem;
}
.down-icon-cont {
  font-size: 1.5rem;
  opacity: 0.5;
}

.flex-resposive {
  display: flex;
}

.create-broadcast-cont {
  width: 70%;
  margin: auto;
}

.opac_half {
  opacity: 9;
  font-weight: bold;
  font-size: 13px;
}

.border-btm {
  font-size: 13px;
  border-bottom: 1px solid #dbdbdb;
}

.clear-btn {
  background-color: lightgrey;
  color: black;
}

.enquiry-list-cont {
  width: 85%;
  margin: auto;
}

.warning-button {
  --bs-btn-color: #e2b473;
  --bs-btn-border-color: #e2b473;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #e2b473;
  --bs-btn-hover-border-color: #e2b473;
  --bs-btn-focus-shadow-rgb: 13, 110, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #e2b473;
  --bs-btn-active-border-color: #e2b473;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #e2b473;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #e2b473;
  --bs-gradient: none;
}

.organe-fnt {
  color: #e2b473 !important;
}

.cont-border {
  border: 1px solid #dbdbdb;
}

.enquiry-footer-card {
  border-top: 1px solid #dbdbdb;
  position: relative;
}

.enquiry-pag-list a {
  color: #247395 !important;
}

.enquiry-page-cont a:active {
  background-color: #247395 !important;
  color: #ffff !important;
}

.enquiry-page-cont a:hover {
  background-color: #247395;
  color: #ffff !important;
}
.enuiryfont {
  font-family: "f2" !important;
}

.fnt-fam-f3 {
  font-family: "f3" !important;
}

.bor-right {
  border-right: 1px solid #dbdbdb;
}

.enquiry-view-btn-bor {
  border: var(--bs-border-width) var(--bs-border-style) #c5c5c5 !important;
}

.opac-30 {
  opacity: 32%;
}

.wid_8-7r {
  width: 8.7rem;
}

.opac-85 {
  opacity: 85%;
}

.wid_15 {
  width: 15rem;
}

.dropdown-btm-grey {
  border: none;
  border-bottom: 2px solid gray;
}

.enq-vie-tab-model-box {
  width: 29rem;
  background: white;
  box-shadow: 0px 0px 8px -1px #c6c6c6;
  border-top: 3px solid #247395;
  position: absolute;
  min-height: 15rem;
  max-width: auto;
  max-height: auto;
  left: 4.5rem;
  top: 2.3rem;
}

.quo-scop-tab-model-box {
  width: 29rem;
  background: white;
  box-shadow: 0px 0px 8px -1px #c6c6c6;
  border-top: 3px solid #247395;
  position: absolute;
  max-width: auto;
  max-height: auto;
  left: 4.5rem;
  top: 2.3rem;
}

.enq-view-modelbox-arrow-cont {
  position: absolute;
  top: -3.2rem;
  font-size: 3.2rem;
  color: #247395;
}

.pd_03r {
  padding: 0.3rem !important;
}

.enq-drop-whole-container {
  z-index: 111;
  padding: 10px;
  right: 5px;
  top: 7px;
}

.over-flow-x-scroll {
  overflow-x: auto;
}

.pad-07r {
  padding: 0.7rem;
}

.pad-046r {
  padding: 0.46rem;
}

.light-grey-bg {
  background: #cbc9c9;
}

.quo-scope-input {
  border: none;
  // border-bottom: 1px solid #dbdbdb;
  width: 5rem;
  margin-right: 2px;
}

.input_bottam {
  border-bottom: 1px solid #dbdbdb;
}

.fs-09r {
  font-size: 0.9rem;
}

.quo-radio-btn {
  padding: 0.2rem;
  margin-right: 4px;
  border-radius: 10px;
  border: 2px solid #247395;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.input-bor-btm {
  border: none;
  border-bottom: 1px solid #dbdbdb;
}

.bg-lig-grey {
  background: #f4f1f1;
}

.pointer-cur {
  cursor: pointer;
}

.po-sc-drop-down {
  position: absolute;
  right: 1rem;
  top: -3.2rem;
  font-size: 3.2rem;
  color: #247395;
}

.fnt-fam-f1 {
  font-family: "f1";
}

.m-hgt-70vh {
  min-height: 70vh;
}

.wid-11r {
  width: 11rem;
}

.grn-filter-btn {
  font-size: 14px;
  color: #fff;
  height: 41px;
  border-radius: 3px;
  margin-left: 10px;
  padding: 0 13px;
  border: 0;
}
.search-grn {
  font-size: 14px;
  color: #fff;
  height: 34px;
  border-radius: 3px;
  margin-left: 0px;
  padding: 0 13px;
  border: 0;
}

.grn-btn-cont-fst-para {
  width: 200px;
  margin: 10px 10px 10px 0;
}

.grn-btn-cont-sec-para {
  margin: 5px 66px 5px 0;
  width: 111px;
  height: 20px;
  text-align: center;
}

.blue-btn-bor-thick {
  border: 2px solid #42a4ce;
}

.fnt-blue-col {
  color: #247395 !important;
}

.mar-r-1r {
  margin-right: 1rem !important;
}

.grn-table-wid {
  margin-right: 1rem;
  table {
    tbody td {
      font-family: "f1";
      border-bottom: 1px solid #ddd;
      // min-width: 80px;
    }
    tbody {
      border: none !important;
      border-style: none !important;
    }
    th {
      border: none !important;
      border-style: none !important;
      font-size: 14px;
      font-family: "f1";
    }
    thead {
      border: none !important;
      border-style: none !important;
    }
    tr {
      border: none !important;
      border-style: none !important;
    }
  }
}

.rn-reply-btn {
  width: 101px;
  display: block;
  margin-right: 5px;
  margin-top: 7px;
  line-height: 30px;
  text-align: center;
  height: 33px;
}

.rn-sub-btn {
  display: block;
  // width: 170px;
  line-height: 19px;
  text-align: center;
  height: 33px;
}

.thin-light-grey-bg {
  background-color: rgb(245, 245, 245);
}

// .create-inv-cont {
//   width: 40%;
// }

.file-upload-cont-bg {
  background-color: #f4f4f4;
}

.create-inv-delete-icon-cont {
  width: 33px;
  height: 33px;
  display: grid;
  place-content: center;
  border-radius: 50%;
  background: #ffff;
}

.file_upload {
  .create-inv-file-cont-text-cont {
    width: 100%;
    top: 47px;
    position: absolute;
    text-align: center;
    z-index: 1;
  }
}

.fs-14p {
  font-size: 14px;
}

.wid-105-perc {
  width: 105% !important;
}

.wid-97-perc {
  width: 97% !important;
}

.invoice-first-sec {
  // margin: 5px 33px 5px 0px;
  min-width: 310px;
  max-width: 500px;
}

.table-chk-cont {
  input[type="checkbox"] {
    accent-color: #247395;
  }
}

.invoce-2ndtable-header-sec-cont {
  width: 176px;
  margin-right: 42px;
}

.invoce-2ndtable-header-last-cont {
  width: 95px;
  margin-right: 15px;
}

.min-wid-1k {
  min-width: 1176px;
}

.fs-14px {
  font-size: 14px !important;
}

.fs-13px {
  font-size: 13px !important;
}

.wid-1010 {
  width: 1010px;
}

.fs-11px {
  font-size: 11px !important;
}

.invoice-table-cont {
  th {
    font-size: 14px !important;
  }

  td {
    font-size: 14px !important;
  }
}

.invoice-table-model-arrow-cont {
  position: absolute;
  top: -3rem;
  left: -1px;
  font-size: 3rem;
  color: #247395;
}

.invoice-final-output-cont {
  width: 83%;
  margin: auto;
}

.invoice-download-btn {
  height: 31px;
  border-radius: 1px;
  width: 96px;
}

.invoice-print-btn {
  height: 31px;
  border-radius: 1px;
  width: 69px;
}

.heading-cont-invoice {
  width: 270px;
  display: flex;
}

.invoice-btn-cont {
  width: 330px;
  justify-content: end;
}

.invoice-logo-container {
  height: 210px;
  width: 100%;
  background: #f3e7d9;
}

.fs-15px {
  font-size: 15px;
}

.grn-table {
  th {
    min-width: 100px;
  }
  td {
    min-width: 100px;
  }
}
.small-blue-button {
  width: 78px;
  border-radius: 2px;
  height: 33px;
}

.quotation-nd-table-cont {
  table > :not(:first-child) {
    border-top: none;
  }
  thead {
    border-bottom: 1px solid #dbdbdb;
  }
  th {
    border: 1px solid #dbdbdb;
  }
}

.bor-top {
  border-top: 1px solid #dbdbdb;
}

.bor-left {
  border-left: 1px solid #dbdbdb;
}

.add-role-btn {
  width: 79px;
  height: 33px;
}

.po-view-side-cont {
  width: 300px;
  p {
    font-size: 14px;
    span {
      display: inline-block;
      width: 163px;
    }
  }
}

.checkbox-drop-box {
  position: absolute;
  max-height: 230px;
  overflow-y: scroll;
  background-color: #ffff !important;
  border: 1px solid #dbdbdb;
  border-top: none;
  z-index: 1;
  input {
    cursor: pointer;
  }
  li {
    padding: 0px 11px;
  }

  // border-radius: 5px;
  width: 100%;
}

.checkbox-drop-parent-box {
  position: relative;
}
