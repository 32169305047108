@font-face {
  font-family: "f1";
  src: url("../fonts/Poppins/Poppins-Regular.ttf");
}

@font-face {
  font-family: "f2";
  src: url("../fonts/Poppins/Poppins-Medium.ttf");
}
@font-face {
  font-family: "f3";
  src: url("../fonts/Poppins/Poppins-Bold.ttf ");
}
