@import "./assets/sass/fonts.scss";
@import "./assets/sass/theme.scss";

@media (max-width: 567px) {
  .top_bar {
    text-align: center;
    .logo_img img {
      width: 100% !important;
    }
  }
  .log_des {
    text-align: center;
    img {
      width: 70% !important;
    }
  }
  .card-container {
    width: 90%;
    padding: 8px !important;
  }
  .dc-top-view {
    p {
      width: 100% !important;
    }
  }

  .err-input {
    font-size: 8px !important;
  }
}

@media (max-width: 767px) {
  .rit-mobile-regis {
    .form-control {
      width: 87% !important;
    }
  }
  .rit-mobile-regis2 {
    .form-control {
      width: 78% !important;
    }
  }
  .user-login-details-cont {
    margin: 0 !important;
    padding: 0 20px;
  }
  .user-login-details-backarrow-cont {
    margin: 0 !important;
  }
  .hede-top,
  .top_bar {
    min-width: 330px;
  }
  .rit-body {
    width: 100%;
    overflow-x: hidden;
  }
  .log_cent {
    height: 90% !important;

    .form_box p {
      text-align: center;
      margin: 7px 0;
      font-size: 13px;
    }
  }
  .custom-file-input {
    width: 100% !important;
  }
  .rit-ad-prod {
    position: inherit !important;
    padding: 20px;
  }
  .user-list-userlist-container {
    min-width: 330px;
    overflow: scroll;
  }
  .filter-btn,
  .map-btn {
    width: 79px !important;
    height: 35px !important;
    margin-left: 4px !important;
    font-size: 12px !important;
  }

  .css-1wvake5 {
    &.ps-collapsed {
      width: 150% !important;
      min-width: 150% !important;
      left: 0 !important;
    }
  }
  .tbale-scroll-horizontal {
    width: 97% !important;
    overflow: scroll !important;
  }

  .cert_ficate {
    .img-none {
      display: none;
    }
  }
  .prof-left {
    padding-left: 0px !important;
  }
  .left-add-user {
    margin-top: 40px;
    margin-left: 15px;
  }
  .acc-payment-cont {
    width: 94% !important;
    margin-left: 0.75rem !important;
    margin-right: auto !important;
  }
  .dc-top-view {
    p {
      width: 50%;
    }
  }
  .login-bg {
    .log_cent {
      padding: 20px;
    }
  }
  .enqiry-view-2nd-sec {
    width: calc(100% - 0px) !important;
  }
  .po-view-side-cont {
    width: 100% !important;
  }
}

@media (min-width: 768px) {
  .w-sm-auto {
    width: auto !important;
  }
  .rit-body {
    width: 100%;
  }
  .account-settings-left {
    min-height: 79vh;
  }
  .lin-rit-lg-profile {
    border-right: 1px solid #c6c6c6;
  }
  .change-admin-btn {
    margin-bottom: 0 !important;
  }
  .prof-left {
    padding-left: 30px !important;
  }
  .left-add-user {
    margin-top: 40px;
    margin-left: 15px;
  }
  .dc-top-view {
    p {
      width: 170px;
    }
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .shoe-acces-filter {
    width: 97% !important;
  }
  .dc-prod-table {
    width: 97% !important;
  }
  .rit-mobile-regis {
    .form-control {
      width: 80% !important;
    }
  }
  .rit-mobile-regis2 {
    .form-control {
      width: 64% !important;
    }
  }
  .enqiry-view-2nd-sec {
    width: calc(100% - 300px) !important;
  }
}
@media (max-width: 991px) {
  .product-table {
    overflow: scroll;
  }
}
@media (min-width: 992px) {
  .rit-add-user {
    padding-left: 60px !important;
  }
  .left-add-user {
    margin-top: 40px;
    margin-left: 50px;
  }
  .rit-mobile-regis {
    .form-control {
      width: 85% !important;
    }
  }
  .rit-mobile-regis2 {
    .form-control {
      width: 68% !important;
    }
  }
  .enqiry-view-2nd-sec {
    width: calc(100% - 400px) !important;
  }
  .hede-top {
    // width: calc(100% - 47px);
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .product-table {
    width: 100%;
    overflow: scroll;
  }
  .rit-mobile-regis {
    input {
      width: 85% !important;
    }
  }
}

@media (max-width: 1400px) {
  .left-dc {
    width: calc(100% - 450px);
    transition: 0.2s all linear;
  }
  .rit-dc-stage1 {
    margin-right: -450px;
    width: 400px !important;
  }
  .rit-dc-stage {
    margin-right: 0px;
    width: 400px !important;
  }
  .purchaseOrder {
    width: 100%;
    overflow-x: scroll;
    min-height: 400px;
    table {
      width: 65rem;
    }
    tr {
      // display: inline;
    }
    tbody {
    }
    th {
    }
    td {
      // height: 100px !important;
    }
  }
}
@media (max-width: 991px) {
  .over-flow-x-scroll {
    overflow-x: auto !important;
    width: 100%;
  }
}

@media (min-width: 1401px) {
  .lin-rit-lg-profile {
    border-right: 1px solid #c6c6c6;
  }
  .account-settings-left {
    width: 350px !important;
  }
  .prof-left {
    padding-left: 80px !important;
  }
  .rit-add-user {
    padding-left: 100px !important;
  }
  .left-add-user {
    width: 300px;
    margin-top: 40px;
    margin-left: 90px;
  }
  .user-list-userlist-container {
    width: 60% !important;
  }
  .acc-payment-cont {
    width: 46% !important;
    margin-left: 1rem !important;
    margin-right: auto !important;
  }
  .rit-dc-stage1 {
    margin-right: -450px;
    width: 400px !important;
  }
  .rit-dc-stage {
    margin-right: 0px;
    // width: 400px !important;
    width: 350px !important;
  }
  .hede-top {
    width: calc(100% - 0px);
  }
}
